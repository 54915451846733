import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import LoadingSpin from 'react-loading-spin';
import './SpinModal.css';

class SpinModal extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      smShow: false,
      error: false,
    };
  }

  setMode = (mode) => {
    this.setState({
      mode,
    });
  };

  render() {
    return (
        <Modal
          size="sm"
          backdrop="static"
          show={this.props.showModal}
        >
        <Modal.Body style={{"textAlign": "center", padding: "0px !important"}}><LoadingSpin primaryColor = 'rgba(20, 85, 53, 99)'/></Modal.Body>
        </Modal>
    );
  }
}

export default SpinModal;
