import React, {Component} from 'react';
import {Pie} from 'react-chartjs-2';

export default class SimplePieWidgetComponentV2 extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            data:''
        }
    }

    componentDidMount = () => {
        this.state.data = {
            title: this.props.data.title,
            labels: this.props.data.data.labels,
            datasets: [
            {
                label:this.props.data.label,
                data: this.props.data.data.values,
                backgroundColor: this.props.data.backColor,
                hoverBackgroundColor: this.props.data.hBackColor
            }
            ]
        };
        this.setState({data : this.state.data});
    }

    render() {
        return (
            <div className={this.props.length}>
                <div className="ibox ">
                    <div className="ibox-title">
                        <h5>{this.props.data.title}</h5>
                        <div className="ibox-tools">
                                    <a className="collapse-link">
                                        <i className="fa fa-chevron-up"></i>
                                    </a>
                                    <a className="close-link">
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <small></small>
                        </div>
                        <div className="ibox-content">
                        <div >
                            <Pie
                            data={this.state.data}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}