import React, {Component} from 'react';
import {Line} from 'react-chartjs-2';

export default class FullLineWidgetComponentV2 extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            data:''
        }
        
    }


    componentDidMount = () => {
        this.state.data = {
            title: this.props.data.title,
            totalTitle: this.props.data.totalTitle,
            labels: this.props.data.data.labels,
            total: this.props.data.total,
            description: this.props.data.description,
            datasets: [
            {
                fill: false,
                label: "Visualizações",
                data: this.props.data.data.values,
                borderColor: "rgba(75,192,192,1)",
                lineTension: 0.1,
            }
            ]
        };
        this.setState({data : this.state.data});
    }

    render() {
        return (
            <div className="col-lg-6">
            <div className="ibox ">
            <div className="ibox-title">
            <h3 className="font-bold no-margins">
                {this.props.data.title}
            </h3>
                <small></small>
                </div>
                <div className="ibox-content">
                    <div className="m-t-sm">
                        <div className="row">
                            <div className="col-md-10">
                                <div>
                                <Line options={{
                                            scales: {
                                                yAxes: [{
                                                    ticks: {
                                                        beginAtZero: true,
                                                        stepSize: 1,
                                                        steps: 1,
                                                        type: 'linear',
                                                    }
                                                }]
                                            }
                                        }}
                                        data={this.state.data}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        );
    }

}