import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';

export default class SimpleBarWidgetComponentV2 extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            data:''
        }
    }

    componentDidMount = () => {
        this.state.data = {
            title: this.props.data.title,
            labels: this.props.data.data.labels,
            datasets: [
            {
                label:this.props.data.label,
                data: this.props.data.data.values,
                backgroundColor: this.props.data.backColor,
                hoverBackgroundColor: this.props.data.hBackColor
            }
            ]
        };
        this.setState({data : this.state.data});
    }

    options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }

    render() {
        return (
            <div className={this.props.length}>
            <div className="ibox ">
                <div className="ibox-title">
                    <h5>{this.props.data.title}</h5>
                    <small></small>
                </div>
                <div className="ibox-content">
                <div >
                <Bar options={{
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        stepSize: 20,
                                        steps: 1,
                                        type: 'linear',
                                    }
                                }]
                            }
                        }}
                data={this.state.data}/>
            </div></div>
            </div>
            </div>
        );
    }

}