import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Router, Route, browserHistory} from 'react-router';

import DashComponente from './components/dashboard/dashComponente';

ReactDOM.render(
    <Router history={browserHistory}>
        <Route path='/' component={App}>
             <Route path='/dashv2' component={DashComponente} />
            {<Route path="*" component={Error} />}
        </Route>
    </Router>
    , document.getElementById('root'));
serviceWorker.register();