import React, {Component} from 'react';

export default class SimpleWidgetComponentV2 extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            data:''
        }
    }


    componentDidMount = () => {
        this.state.data = {
            period: this.props.data.period,
            title: this.props.data.title,
            total:this.props.data.data.total,
            roomId:this.props.data.roomId
        };
        this.setState({data : this.state.data});
    }

    render() {
        return (
            <div className={this.props.length}>
                <div className="widget navy-bg p-lg text-center">
                    <div className="">
                        <h5>{this.state.data.title}</h5>
                    </div>
                    <div className="">
                        <h1 className="no-margins">{this.state.data.total}</h1>
                    </div>
                </div>
            </div>
        );
    }

}