import React, { Component } from "react";
import SimpleBarWidgetComponentV2 from "./widgets/simpleBarWidgetComponentV2";
import SimplePieWidgetComponentV2 from "./widgets/simplePieWidgetComponentV2";
import FullLineWidgetComponentV2 from "./widgets/fullLineWidgetComponentV2";
import SimpleWidgetComponentV2 from "./widgets/simpleWidgetComponentV2";
import jwt_decode from "jwt-decode";
import SpinModal from "../SpinModal";

var api = require("../../axios");

export default class DashComponentV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infosDash: "",
      showSpin: true,
    };
  }

  loadInfo = async () => {
    var roomId = new URL(window.location.href).searchParams.get("room");
    var token = new URL(window.location.href).searchParams.get("token");
    var decoded = jwt_decode(token);
    var emailExternalUser = decoded.email;
    return api
      .getDash3(
        emailExternalUser,
        roomId,
        this.state.token,
        this.state.userToken
      )
      .then(async (response) => {
        console.log(response.data);
        this.setState({ infosDash: response.data });
        this.setState({ showSpin: false });
      });
  };

  componentDidMount = () => {
    this.loadInfo();
  };

  render() {
    return (
      <div className="wrapper wrapper-content">
        <SpinModal showModal={this.state.showSpin} />
        <div className="row">
          {this.state.infosDash !== ""
            ? this.state.infosDash.sort((a, b) => a.order > b.order ? 1 : -1).map((row, i) => {
                if (row.type === "bar")
                  return (
                    <SimpleBarWidgetComponentV2 data={row} length="col-lg-3" />
                  );
                if (row.type === "pie")
                  return (
                    <SimplePieWidgetComponentV2 data={row} length="col-lg-3" />
                  );
                if (row.type === "full")
                  return <FullLineWidgetComponentV2 data={row} />;
                if (row.type === "card")
                  return (
                    <SimpleWidgetComponentV2 data={row} length="col-lg-3" />
                  );
              })
            : ""}
        </div>
      </div>
    );
  }
}
